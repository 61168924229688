<template>
  <nav>
    <ul class="flex flex-auto items-center gap-2">
      <template v-for="(item, index) in items" :key="index">
        <li v-if="item.group">
          <app-dropdown-menu
            :key="item.title"
            v-slot="{ open }"
            class="group flex items-center"
            :class="[
              navigationItemClass,
              { [navigationItemActiveClass]: item.selected },
            ]"
            item-class="!text-base !font-normal mb-1 last:mb-0"
            :items="item.items"
          >
            {{ item.title }}
            <span
              class="w-0 overflow-hidden text-right transition-[width] group-hover:w-6"
              :class="{ '!w-6': open }"
            >
              <app-icon
                class="transition"
                :class="{ 'rotate-180': open }"
                icon="ph:caret-down"
                :size="16"
              />
            </span>
          </app-dropdown-menu>
        </li>

        <li v-if="!item.divider && !item.group">
          <nuxt-link
            :active-class="navigationItemActiveClass"
            :class="navigationItemClass"
            :to="item.to"
          >
            {{ item.label }}
          </nuxt-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import type { DropdownMenuItemDefinition } from "@pollen/ui-library";

defineProps<{
  items: DropdownMenuItemDefinition[];
}>();

const navigationItemClass =
  "block rounded p-2 transition hover:bg-primary-50 hover:text-primary-950";
const navigationItemActiveClass =
  "font-semibold bg-primary-100 font-semibold text-primary-950";
</script>
